<template>
  <div class="admin-products-listing">
    <title-bar addBtn :addUrl="getAddProductURL()" title="Products" />
    <search class="mb-4" @inputChange="inputChange" />
    <vue-good-table
      class="custom-table-style possible-vertical-align"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="products"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">

        <span class="buttons" v-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditProductURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteProduct(props.row)" />
          </div>
        </span>

        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'priceWithTax'"
        >
          {{ props.formattedRow[props.column.field] | price }}
        </span>

        <span v-else-if="props.column.field == 'name'">
          <div class="product-name">
            <div v-if="props.row.fileUrl" class="product-photo">
              <img
                :src="`${props.row.fileUrl}`"
                :alt="`${props.formattedRow[props.column.field]}`"
              />
            </div>
            <span> {{ props.formattedRow[props.column.field] }}</span>
          </div>
        </span>

        <span v-else-if="props.column.field == 'enabled'">
          <b-badge :variant="props.row.enabled ? 'success' : 'danger'"
            >{{ props.row.enabled ? 'ENABLED' : 'DISABLED' }}
          </b-badge>
        </span>

        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import Search from '@/components/Inputs/Search.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    Search,
    VueGoodTable,
    TablePagination,
    EditBtn,
    DeleteBtn,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      search: '',
      isLoading: false,
      perPage: 15,
      currentPage: 1,
      pages: 0,
      products: null,

      columns: [
        {
          label: 'Product',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Price',
          field: 'priceWithTax',
          sortable: false,
          width: '120px',
          tdClass: 'text-left pl-4',
          thClass: 'text-left pl-4'
        },
        {
          label: 'Enabled',
          field: 'enabled',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          width: '100%',
        },
      ],
    }
  },
  methods: {
    loadProducts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[name]': 'asc'
      }
      if (this.search && this.search.length) {
        params.search = this.search
      }
      this.$ProductVariants
        .getCollection({ params }, 'product_variant_list')
        .then(response => {
          this.pages = response.data['hydra:totalItems']
          this.products = response.data['hydra:member']
        })
    },
    deleteProduct(productVariant) {
      this.$Products
        .deleteResourceByUrl({ url: productVariant.product })
        .then(productResponse => {
          if (productResponse.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Product deleted',
              '',
              'success',
            )
            this.loadProducts()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
    inputChange(value) {
      this.search = value
      this.loadProducts()
    },
    onPageChange() {
      this.loadProducts()
    },
    getAddProductURL() {
      return `/add-product`
    },
    getEditProductURL(id) {
      return `/products/${id}/edit`
    },
  },
}
</script>
